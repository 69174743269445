import * as s from "./jobsDescriptionsStyles.jsx";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export const CarouselComponent = ({ pasteName, numberFiles }) => {
  const slides = [];

  for (let i = 0; i < numberFiles; i++) {
    slides.push(
      <div key={i + 1} style={{ width: "100%", height: "auto" }}>
        <img
          alt=""
          src={`/carousel/${pasteName}/${i + 1}.png`}
          style={{
            width: "100%",
            height: "auto",
            backgroundPosition: "center",
            objectFit: "cover",
          }}
        />
      </div>
    );
  }

  return (
    <Carousel
      autoPlay
      thumbWidth={0}
      showThumbs={false}
      animationHandler="fade"
      swipeable={false}
      showArrows={false}
    >
      {slides}
    </Carousel>
  );
};

export const JobsDescriptions = ({
  title,
  description,
  pasteName,
  numberFiles,
}) => {
  const footerPosition = localStorage.getItem("footer-class");

  const scrollDown = () => {
    window.scrollTo({
      top: parseInt(footerPosition) + 820,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const abrirLink = () => {
    var url = "https://gvroqqc5p6q.typeform.com/to/ZBLSvwsJ";
    window.open(url, "_blank");
  };

  return (
    <>
      <s.Container>
        <s.Content>
          <s.BoxContent>
            <s.BoxTitle>
              <s.Title>{title}</s.Title>
            </s.BoxTitle>
            <s.BoxDescription>
              <s.Description>{description}</s.Description>

              {title === "Tráfego Pago" ? (
                <>
                  <s.Button onClick={() => abrirLink()}>
                    Entre em contato!
                  </s.Button>
                </>
              ) : title === "Growth Partner" ? (
                <>
                  <s.Button onClick={() => scrollDown()}>
                    Entre em contato!
                  </s.Button>
                </>
              ) : (
                <>
                  <s.Button onClick={() => scrollToTop()}>
                    Entre em contato!
                  </s.Button>
                </>
              )}
            </s.BoxDescription>
          </s.BoxContent>
          <s.BoxCarousel>
            <CarouselComponent
              pasteName={pasteName}
              numberFiles={numberFiles}
            />
          </s.BoxCarousel>
        </s.Content>
      </s.Container>
    </>
  );
};
