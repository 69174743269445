import { useEffect, useRef, useState } from "react";
import { data } from "./sliderData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as s from "./sliderStyle.jsx";
import { SliderDescription } from "./sliderDescriptions/sliderDescriptions.jsx";

export const SliderComponent = () => {
  const [count, setCount] = useState(0);
  const sliderRef = useRef(null);

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 6,
    slidesToScroll: 1,
    draggable: true,
    focusOnSelect: true,
    beforeChange: (current, next) => setCount(next),
    style: {
      width: windowWidth <= 481 ? "800px" : "1500px",
    },
  };

  const modifyImgIndex = (increment) => {
    let newCount = (count + increment) % data.length;
    if (newCount < 0) {
      newCount += data.length;
    }
    setCount(newCount);
  };

  const handleDataFromChild = (index) => {
    setCount(index);
    sliderRef.current.slickGoTo(index);
  };

  return (
    <>
      <s.Body>
        <s.Container>
          <s.BackgroundShadow />
          <s.Image $img={data[count].image}>
            <SliderDescription
              data={data}
              sendDataToParent={handleDataFromChild}
              count={count}
            />
            <s.SliderContainer>
              <div className="slider-container">
                <Slider {...settings} ref={sliderRef}>
                  {data.map((item, index) => (
                    <s.ContainerCard key={index} $index={index} $count={count}>
                      <s.Card $img={item.image2} $index={index} $count={count}>
                        <s.Shadows>
                          <s.Titles>
                            <s.Title>{item.title2}</s.Title>
                            <s.Title2> {item.title}</s.Title2>
                          </s.Titles>
                        </s.Shadows>
                      </s.Card>
                    </s.ContainerCard>
                  ))}
                </Slider>
              </div>
              <s.Arrows>
                <s.Left
                  size={16}
                  onClick={() => {
                    modifyImgIndex(-1);
                    prevSlide();
                  }}
                />
                <s.Right
                  size={16}
                  onClick={() => {
                    modifyImgIndex(+1);
                    nextSlide();
                  }}
                />
              </s.Arrows>
            </s.SliderContainer>
          </s.Image>
        </s.Container>
      </s.Body>
    </>
  );
};
