import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

export const Container = styled.div`
  width: auto;
  height: auto;
  font-family: "Sora sans", Sans-serif;
`;

export const FooterElement = styled.footer`
  width: auto;
  height: auto;
  background-color: #0f0f0f;
  justify-content: space-between;
  padding: 40px;

  @media only screen and (max-width: 480px) {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    display: grid;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: flex;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: flex;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
  }
`;

export const Content = styled.div`
  display: grid;
  @media only screen and (max-width: 480px) {
    margin-top: 20px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 20px;
  }

  &:nth-last-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const ContentIcons = styled.div`
  display: table;
  width: 180px;
  text-align: center;
  height: auto;
`;

export const Img = styled.img`
  height: auto;
  width: 100%;
  width: 192px;

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 300px;
  }
`;

export const H2 = styled.h2`
  color: darkgray;
`;

export const LinkElement = styled(Link)`
  text-decoration: none;
  color: darkgray;
  font-weight: 600;
  @media only screen and (max-width: 480px) {
    margin-top: 10px;
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 10px;
  }

  &:hover {
    color: #fefefe;
  }
`;

export const Instagram = styled(FaInstagram)`
  margin: 20px;
  color: darkgray;
  &:hover {
    color: #fefefe;
    cursor: pointer;
  }
`;

export const Facebook = styled(FaFacebook)`
  margin: 20px;
  color: darkgray;
  &:hover {
    color: #fefefe;
    cursor: pointer;
  }
`;

export const Whatsapp = styled(FaWhatsapp)`
  margin: 20px;
  color: darkgray;
  &:hover {
    color: #fefefe;
    cursor: pointer;
  }
`;
