import styled from "styled-components";
import { Link } from "react-router-dom";
import { IoMdReorder } from "react-icons/io";
import { IoIosClose } from "react-icons/io";

export const HeaderVideoContainer = styled.div`
  width: 100vw;
  height: 800px;
  overflow: hidden;
  pointer-events: none;
  @media only screen and (max-width: 480px) {
    display: none;
    position: absolute;
    z-index: -99999;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    display: none;
    position: absolute;
    z-index: -99999;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
  }

  @media only screen and (min-width: 1201px) {
  }
`;

export const HeaderVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

export const HeaderImageContainer = styled.div`
  width: 100vw;
  height: 800px;
  overflow: hidden;
  @media only screen and (max-width: 480px) {
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: none;
    position: absolute;
    z-index: -99999;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: none;
    position: absolute;
    z-index: -99999;
  }

  @media only screen and (min-width: 1201px) {
    display: none;
    position: absolute;
    z-index: -99999;
  }
`;

export const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Container = styled.div`
  width: -webkit-fill-available;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  @media only screen and (max-width: 480px) {
    padding-right: 0px;
    padding-left: 0px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding-right: 10px;
    padding-left: 10px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding-right: 30px;
    padding-left: 30px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding-right: 160px;
    padding-left: 160px;
  }

  @media only screen and (min-width: 1201px) {
    padding-right: 230px;
    padding-left: 230px;
  }
`;

export const Reorder = styled(IoMdReorder)`
  font-size: 34px;
  text-decoration: none;
  color: #ffffffd3;
  font-weight: bolder;
  transition: color 0.3s ease;

  &:hover {
    color: #ffffff;
    cursor: pointer;
  }
`;

export const Close = styled(IoIosClose)`
  font-size: 34px;
  text-decoration: none;
  color: #ffffffd3;
  font-weight: bolder;
  transition: color 0.3s ease;

  &:hover {
    color: #ffffff;
    cursor: pointer;
  }
`;

export const Mark = styled.ul`
  width: auto;

  @media only screen and (max-width: 480px) {
    padding: 20px;
    &:nth-child(1) {
      position: ${({ $state }) => ($state ? "fixed" : "")};
      z-index: 99999;
    }
    &:nth-child(2) {
      width: 100%;
      height: auto;
      position: fixed;
      z-index: 20;
      top: ${({ $state }) => ($state ? "0" : "-250px")};
      left: 0px;
      background-color: #232323;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 2.5;
      transition: all 300ms ease;
      padding: 20px 0px 20px 0px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    &:nth-child(1) {
      position: ${({ $state }) => ($state ? "fixed" : "")};
      z-index: 99999;
    }
    &:nth-child(2) {
      width: 100%;
      height: auto;
      position: fixed;
      z-index: 20;
      top: ${({ $state }) => ($state ? "0" : "-250px")};
      left: 0px;
      background-color: #232323;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 2.5;
      transition: all 300ms ease;
      padding: 20px 0 20px 0;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 20px;
    &:nth-child(1) {
      display: none;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding: 20px;
    &:nth-child(1) {
      display: none;
    }
  }

  @media only screen and (min-width: 1201px) {
    padding: 20px;
    &:nth-child(1) {
      display: none;
    }
  }
`;

export const ListItem = styled.li`
  display: inline;
  margin-right: 20px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #ffffffd3;
  font-weight: bolder;
  font-family: "Sora", Sans-serif;
  font-size: 16px;
  transition: color 0.3s ease;

  &:hover {
    color: #ffffff;
  }
`;

export const Button = styled.div`
  border-radius: 35px;
  padding: 10px 20px 10px 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffffd3;
  width: 150px;
  color: #000000d2;
  overflow-y: hidden;
  /* margin: 10px 10px 10px 10px; */
`;
