import * as s from "./jobsStyle.jsx";
import React, { useEffect, useState, useRef } from "react";
import { dataJob } from "./dataJob.jsx";
import { JobsDescriptions } from "./jobsDescriptions/jobsDescripitions.jsx";

export const Jobs = () => {
  const scrollDisparoRef = useRef(null);
  const [dinamics, setDinamics] = useState(false);
  const [clickJob, setClickJob] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      if (scrollDisparoRef.current) {
        const scrollDisparoPosition =
          scrollDisparoRef.current.getBoundingClientRect().top;
        const screenHeight = window.innerHeight;

        if (scrollDisparoPosition < screenHeight) {
          setDinamics(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    setClickJob({
      title: dataJob[0].title,
      description: dataJob[0].description,
      pasteName: dataJob[0].paste.name,
      numberFiles: dataJob[0].paste.numberFiles,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const carouselRef = useRef(null);

  const scrollToRef = () => {
    carouselRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <s.Container>
        <s.H1 ref={scrollDisparoRef}>Nossos Serviços</s.H1>
        <s.LineContainer>
          <s.Line></s.Line>
        </s.LineContainer>
        <s.AboutContainer>
          <s.Abcn>
            {dataJob.map((item, index) => {
              return (
                <s.Box
                  key={index}
                  $index={index}
                  $dinamics={`${dinamics}`}
                  $lenght={dataJob.length}
                  onClick={() => {
                    setClickJob({
                      title: item.title,
                      description: item.description,
                      pasteName: item.paste.name,
                      numberFiles: item.paste.numberFiles,
                    });
                    scrollToRef();
                  }}
                >
                  <s.Img src={item.img} />
                  <s.Title>{item.title}</s.Title>
                  <s.Shadow />
                </s.Box>
              );
            })}
          </s.Abcn>
        </s.AboutContainer>
      </s.Container>
      <div ref={carouselRef}>
        <JobsDescriptions
          title={clickJob.title}
          description={clickJob.description}
          pasteName={clickJob.pasteName}
          numberFiles={clickJob.numberFiles}
        />
      </div>
    </>
  );
};
