import { useEffect, useRef } from "react";
import * as s from "./footerStyle.jsx";

export const Footer = () => {
  const footerRef = useRef(null);
  useEffect(() => {
    localStorage.setItem("footer-class", footerRef.current.offsetTop);
  }, []);

  return (
    <>
      <s.Container ref={footerRef}>
        <s.FooterElement>
          <s.Content>
            <s.Img src="https://i.ibb.co/wgQtDSf/2-FUNDO-TRANSPARENTE.png" />
          </s.Content>
          <s.Content>
            <s.H2>A Agência</s.H2>
            <s.LinkElement>Sobre</s.LinkElement>
            <s.LinkElement
            // to="/servicos"
            >
              Serviços
            </s.LinkElement>
            <s.LinkElement>Contato</s.LinkElement>
          </s.Content>
          <s.Content>
            <s.H2>Comunidade</s.H2>
            <s.LinkElement>Notícias</s.LinkElement>
            <s.LinkElement>Linkedin</s.LinkElement>
            <s.LinkElement>Trabalhe Conosco</s.LinkElement>
          </s.Content>
          <s.Content>
            <s.ContentIcons>
              <s.Instagram size={38} />
              <s.Facebook size={38} />
              <s.Whatsapp size={38} />
            </s.ContentIcons>
          </s.Content>
        </s.FooterElement>
      </s.Container>
    </>
  );
};
