import { About } from "../components/about/about";
import { CompanyNumbers } from "../components/companyNumbers/companyNumbers";
import { Contact } from "../components/contact/contact";
import { Footer } from "../components/footer/footer";
import { Header } from "../components/header/header";
import { Jobs } from "../components/jobs/jobs";

export const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Jobs />
      <CompanyNumbers />
      <Contact />
      <Footer />
    </>
  );
};
