import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const H1 = styled.h1`
  color: #141e2b;
  font-family: "Work Sans", Sans-serif;
  font-size: 64px;
  font-weight: 600;
  padding: 15px;
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -10px;
`;

export const Line = styled.div`
  width: 200px;
  border-bottom: 3px solid #486791;
`;

export const AboutContainer = styled.div`
  font-family: "Work Sans", Sans-serif;
  text-align: left;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const H1c = styled.h1`
  color: #263952;
  margin-bottom: 20px;
  font-family: "Sora sans", Sans-serif;
  font-size: 36px;
  text-align: justify;
`;

export const Abcn = styled.div`
  @media only screen and (max-width: 480px) {
    margin: 20px 20px 0 20px;
    width: 100%;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 20px 20px 0 20px;
    width: 100%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 20px 0 20px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 80px 0 80px;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 135px 0 135px;
  }
`;

export const Box = styled.div`
  border: 1px solid #263952;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  font-family: "DM sans", sans-serif;
  font-size: 24px;
  position: relative;
  transition: all 2000ms ease;

  &:hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    left: ${({ $index, $dinamics }) =>
      $index % 2 === 0
        ? $dinamics === "true"
          ? "0"
          : "-2000px"
        : $dinamics === "true"
        ? "0"
        : "2000px"};
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 100%;
    left: ${({ $index, $dinamics }) =>
      $index % 2 === 0
        ? $dinamics === "true"
          ? "0"
          : "-2000px"
        : $dinamics === "true"
        ? "0"
        : "2000px"};
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: calc(25% - 10px);
    left: ${({ $index, $dinamics, $lenght }) =>
      $index + 1 <= $lenght / 2
        ? $dinamics === "true"
          ? "0"
          : "-2000px"
        : $dinamics === "true"
        ? "0"
        : "2000px"};
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: calc(25% - 10px);
    left: ${({ $index, $dinamics, $lenght }) =>
      $index + 1 <= $lenght / 2
        ? $dinamics === "true"
          ? "0"
          : "-2000px"
        : $dinamics === "true"
        ? "0"
        : "2000px"};
  }

  @media only screen and (min-width: 1201px) {
    width: calc(25% - 10px);
    left: ${({ $index, $dinamics, $lenght }) =>
      $index + 1 <= $lenght / 2
        ? $dinamics === "true"
          ? "0"
          : "-2000px"
        : $dinamics === "true"
        ? "0"
        : "2000px"};
  }
`;

export const Img = styled.img`
  max-width: 50%;
  height: auto;
  filter: hue-rotate(-50deg);
`;

export const Shadow = styled.div`
  width: 100px;
  height: 50px;
  box-shadow: 25px 155px 140px #263952;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #263952; */
`;

export const Title = styled.h2`
  font-family: "DM sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
`;
