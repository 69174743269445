import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
export const Content = styled.div`
  width: 100%;
  height: auto;
`;

export const Img = styled.div`
  background-image: url("/images/contact.jpg");
  background-position: center;
  width: 100%;
  height: auto;
  object-fit: cover;
  background-size: cover;

  @media only screen and (max-width: 480px) {
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    background-attachment: fixed;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    background-attachment: fixed;
  }

  @media only screen and (min-width: 1201px) {
    background-attachment: fixed;
  }
`;

export const Form = styled.form`
  background: #1b1b1b3b;
  backdrop-filter: blur(10px);
  border: 0 none;
  border-radius: 5px;
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 10% 10% 10% 10%;
  position: relative;
  padding: 40px;
`;

export const Fieldset = styled.fieldset`
  border: none;
`;

export const H2 = styled.h2`
  font-family: "Sora sans", Sans-serif;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 7px;
  border-radius: 5px;
`;

export const H3 = styled.h3`
  font-family: "Sora sans", Sans-serif;
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
`;

export const Input = styled.input`
  padding: 15px;
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Sora sans", Sans-serif;
  color: #ffffff;
  font-size: 18px;
  outline: none;
  font-weight: 700;

  &::placeholder {
    color: #ffffff;
    font-weight: 700;
    font-family: "Sora sans", Sans-serif;
    font-size: 18px;
  }
`;

export const Textarea = styled.textarea`
  padding: 15px;
  border: none;
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: "Sora sans", Sans-serif;
  color: #ffffff;
  font-size: 18px;
  outline: none;
  font-weight: 700;
  resize: none;
  height: 200px;

  &::placeholder {
    color: #ffffff;
    font-weight: 700;
    font-family: "Sora sans", Sans-serif;
    font-size: 18px;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: #e2dfb7;
  font-weight: bold;
  color: #2e2e2e;
  border: 0 none;
  border-radius: 1px;
  cursor: pointer;
  padding: 10px;
  font-weight: 700;
  text-decoration: none;
  font-size: 15px;
  font-family: "Sora sans", Sans-serif;
`;
