import { Footer } from "../components/footer/footer";
import { SliderComponent } from "../components/slider/slider";
import { HeaderTop } from "../components/header/header";

export const Services = () => {
  return (
    <>
      <HeaderTop />
      <SliderComponent />
      <Footer />
    </>
  );
};
