import styled from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

export const Body = styled.div`
  background-color: #0f0f0f;
  overflow: hidden;
  position: relative;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #232323;
`;

export const BackgroundShadow = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #00000058;
`;

export const Image = styled.div`
  background-image: ${({ $img }) => `url(${$img})`};
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: 300ms ease all;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export const SliderContainer = styled.div`
  position: relative;
  height: auto;
  @media only screen and (max-width: 480px) {
    position: absolute;
    top: calc(60% + 10px);
    left: 20%;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    right: -5%;
    width: 170%;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    right: -10%;
    width: 150%;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    right: -10%;
    width: 150%;
  }

  @media only screen and (min-width: 1201px) {
    right: -20%;
    width: 100%;
  }
`;

export const Slider = styled.div`
  display: -webkit-box;
  -webkit-box-align: center;
  overflow-x: scroll;
  width: 80%;

  &::-webkit-scrollbar {
    width: 12px;
  }
`;

export const ContainerCard = styled.div`
  //importante

  @media only screen and (max-width: 480px) {
    padding: 20px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    padding: 30px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    padding: 30px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    padding: 30px;
  }

  @media only screen and (min-width: 1201px) {
    padding: 30px;
  }
`;

export const Card = styled.div`
  z-index: 1000;
  background-image: ${({ $img }) => `url(${$img})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 15px;
  transition: 300ms all ease;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  @media only screen and (max-width: 480px) {
    width: 100px;
    height: 150px;
    transform: ${({ $index, $count }) =>
      $index === $count ? "scale(1.2)" : ""};
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    width: 200px;
    height: 300px;
    transform: ${({ $index, $count }) =>
      $index === $count ? "scale(1.2)" : ""};
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 200px;
    height: 300px;
    transform: ${({ $index, $count }) =>
      $index === $count ? "scale(1.2)" : ""};
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 200px;
    height: 300px;
    transform: ${({ $index, $count }) =>
      $index === $count ? "scale(1.2)" : ""};
  }

  @media only screen and (min-width: 1201px) {
    width: 200px;
    height: 300px;
    transform: ${({ $index, $count }) =>
      $index === $count ? "scale(1.2)" : ""};
  }
`;

export const Shadows = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, #000000be);
`;

export const Titles = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin: -1rem 0 0 1rem;
  font-family: "DM sans", Sans-serif;
  font-weight: 600;
`;

export const Title = styled.p`
  color: #b7b7b7b8;
  font-size: 14px;
`;

export const Title2 = styled.p`
  color: #fafafa;
  margin-bottom: 2px;
`;

export const Arrows = styled.div`
  margin: 15px;
`;

export const Left = styled(FaArrowLeft)`
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
  fill: #2c2b2b;
  color: #2c2b2b;
  background-color: #bdbdbd9c;
  border-radius: 100px 100px 100px 100px;
  padding: 12px 14px 12px 14px;
  margin-right: 20px;
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const Right = styled(FaArrowRight)`
  font-family: "Roboto", Sans-serif;
  font-weight: 500;
  fill: #2c2b2b;
  color: #2c2b2b;
  background-color: #bdbdbd9c;
  border-radius: 100px 100px 100px 100px;
  padding: 12px 14px 12px 14px;
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
  }
`;
