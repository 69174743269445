export const data = [
  {
    place: "Website",
    title: "Desenvovimento",
    title2: "Website",
    description:
      "Website é desenvolvido principalmente para gerar leads, conversões e proporcionar uma experiência excepcional aos visitantes.",
    image:
      "https://genext.live/wp-content/uploads/2023/10/samuel-ferrara-uOi3lg8fGl4-unsplash-3.jpg",

    image2:
      "https://genext.live/wp-content/uploads/2023/10/DALL%C2%B7E-2023-10-31-00.40.41-Foto_-Uma-mesa-de-escritorio-bem-iluminada-com-um-laptop-aberto-exibindo-codigo-HTML.-Ao-lado-do-laptop-ha-uma-caneca-de-cafe-um-bloco-de-anotacoes-.png",
  },
  {
    place: "Branding",
    title: "Branding",
    title2: "Identidade Visual",
    description:
      "Desde logotipos icônicos que representam marcas mundialmente reconhecidas até interfaces de aplicativos que simplificam a experiência do usuário.",
    image:
      "https://genext.live/wp-content/uploads/2023/10/christian-joudrey-DuD5D3lWC3c-unsplash-3.jpg",
    image2:
      "https://genext.live/wp-content/uploads/2023/10/DALL%C2%B7E-2023-10-31-00.44.54-Foto_-Uma-mesa-moderna-com-ferramentas-de-design-grafico_-laptop-aberto-com-software-de-design-tablet-grafico-paleta-de-cores-e-esbocos-de-logos.-Um.png",
  },
  {
    place: "CEO Service",
    title: "CEO Service",
    title2: "Serviços de domínio",
    description:
      "CEO lidera estratégias, direciona operações e representa a empresa para garantir crescimento e sucesso sustentável.",
    image: "https://assets.codepen.io/3685267/timed-cards-3.jpg",
    image2:
      "https://i.ibb.co/hyLgpjb/Um-DEUS-CEO-E-C-2a5231c0-1c2a-4f14-b3a7-3ec129764f46.png",
  },
  {
    place: "Tráfego Pago",
    title: "Trágefo Pago",
    title2: "Adsense",
    description:
      "O tráfego pago é a chave para atingir audiências específicas de maneira eficiente e direcionada, gerando lucra para sua empresa.",
    image: "https://assets.codepen.io/3685267/timed-cards-4.jpg",
    image2:
      "https://genext.live/wp-content/uploads/2023/10/DALL%C2%B7E-2023-10-31-00.51.54-Foto_-Em-um-ambiente-de-escritorio-um-profissional-de-descendencia-asiatica-vestindo-uma-camisa-social-azul-esta-ajustando-uma-campanha-publicitari.png",
  },
  {
    place: "Redes Sociais",
    title: "Gestão",
    title2: "Redes Sociais",
    description:
      "As redes Sociais são uma das melhores formar de captar leads e gerar conversões, com elas você pode divulgar sua marca de forma orgânica.",
    image: "https://assets.codepen.io/3685267/timed-cards-5.jpg",
    image2:
      "https://genext.live/wp-content/uploads/2023/10/DALL%C2%B7E-2023-10-31-00.55.21-Foto_-Um-espaco-de-trabalho-moderno-com-um-computador-exibindo-varias-abas-de-redes-sociais-abertas.-Ao-lado-do-teclado-ha-um-smartphone-com-uma-apli.png",
  },
  {
    place: "Vídeos",
    title: "Vídeos",
    title2: "Profissionais",
    description:
      "Combinando criatividade visual, narrativa envolvente e impacto emocional, os vídeos têm a capacidade única de cativar e conectar seu público-alvo de maneira significativa.",
    image: "https://assets.codepen.io/3685267/timed-cards-6.jpg",
    image2:
      "https://genext.live/wp-content/uploads/2023/10/DALL%C2%B7E-2023-10-31-00.56.18-Foto_-Em-um-set-de-filmagem-um-cineasta-de-descendencia-europeia-com-barba-e-usando-um-bone-esta-segurando-uma-camera-de-cinema-profissional.-Ao-seu-.png",
  },
];
