import { useCallback, useEffect, useRef, useState } from "react";
import * as s from "./sliderDescriptionStyle.jsx";

export const SliderDescription = ({ data, sendDataToParent, count }) => {
  const [active, setActive] = useState(0);
  const containerRef = useRef(null);

  const scrollToIndexWithDelay = (index, delay) => {
    setTimeout(() => {
      scrollToIndex(index);
    }, delay);
  };

  const scrollToIndex = useCallback(
    (index) => {
      if (index >= 0 && index < data.length) {
        const container = containerRef.current;
        const element = container.children[index];
        const offsetTop = element.offsetTop;
        const containerTop = container.offsetTop;

        container.scrollTop = offsetTop - containerTop;
      }
    },
    [data]
  );

  useEffect(() => {
    setActive(count);
    scrollToIndex(count);
  }, [count, scrollToIndex]);

  return (
    <>
      <s.Container>
        <s.ContentLine>
          <s.SliderGroup>
            <s.Line />
            {data.map((item, index) => {
              return (
                <s.Dots
                  key={index}
                  $index={index}
                  $active={active}
                  onClick={() => {
                    setActive(index);
                    scrollToIndexWithDelay(index, 400);
                    sendDataToParent(index);
                  }}
                >
                  {index + 1}
                </s.Dots>
              );
            })}
          </s.SliderGroup>
        </s.ContentLine>
        <div className="slider-container">
          <s.MySlider>
            <s.ContentDescription ref={containerRef}>
              {data.map((item, index) => {
                return (
                  <div key={index} ref={containerRef}>
                    <s.Place>{item.place}</s.Place>
                    <s.Description>{item.description}</s.Description>
                    <s.Button>Saiba mais!</s.Button>
                  </div>
                );
              })}
            </s.ContentDescription>
          </s.MySlider>
        </div>
      </s.Container>
    </>
  );
};
