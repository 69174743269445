import { useRef } from "react";
import * as s from "./contactStyle.jsx";
import emailjs from "@emailjs/browser";

export const Contact = () => {
  const form = useRef();

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(serviceId, templateId, form.current, {
        publicKey: publicKey,
      })
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
          alert(
            "Sucesso ao enviar, entraremos em contato o mais rápido possível"
          );
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("Algo inesperado aconteceu, tente novamente mais tarde");
        }
      );
  };

  return (
    <>
      <s.Container>
        <s.Content>
          <s.Img>
            <s.Form ref={form} onSubmit={handleSubmit}>
              <s.Fieldset>
                <s.H2>Agende uma Avaliação para seu Negócio!</s.H2>
                <s.H3>Informe os dados</s.H3>
                <s.Input type="text" placeholder="Nome" name="name" />
                <s.Input
                  type="text"
                  placeholder="Nome do seu Negócio"
                  name="companyName"
                />
                <s.Input type="email" placeholder="Email" name="email" />
                <s.Input type="text" placeholder="Telefone" name="telefone" />
                <s.Textarea placeholder="O que você procura?" name="wish" />
                <s.Button>Enviar</s.Button>
              </s.Fieldset>
            </s.Form>
          </s.Img>
        </s.Content>
      </s.Container>
    </>
  );
};
