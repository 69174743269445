import { useEffect, useState } from "react";
import * as s from "./headerStyle.jsx";

export const HeaderTop = () => {
  const [state, setState] = useState(false);
  const modifyState = () => setState(!state);

  return (
    <>
      <s.Container>
        <s.Mark onClick={modifyState} $state={state}>
          {state ? <s.Close /> : <s.Reorder />}
        </s.Mark>
        <s.Mark $state={state}>
          <s.ListItem>
            <s.StyledLink to="/">Home</s.StyledLink>
          </s.ListItem>
          <s.ListItem>
            <s.StyledLink
            // to="/servicos"
            >
              Serviços
            </s.StyledLink>
          </s.ListItem>
          {/* <s.ListItem>
            <s.StyledLink to="/">Sobre</s.StyledLink>
          </s.ListItem> */}
          {/* <s.ListItem>
            <s.StyledLink to="/">Blog</s.StyledLink>
          </s.ListItem> */}
          <s.ListItem>
            <s.StyledLink to="https://wa.me/5511951594604?text=Olá, gostaria de conversar a respeito de uma ideia!">
              Contato
            </s.StyledLink>
          </s.ListItem>
        </s.Mark>
        <s.Mark>
          {/* <s.ListItem>
            <s.StyledLink to="/servicos">MIND</s.StyledLink>
          </s.ListItem> */}
        </s.Mark>
        <s.Mark>
          <s.ListItem>
            <s.StyledLink to="https://wa.me/5511951594604?text=Olá, gostaria de conversar a respeito de uma ideia!">
              <s.Button>Entre em contato</s.Button>
            </s.StyledLink>
          </s.ListItem>
        </s.Mark>
      </s.Container>
    </>
  );
};

export const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <HeaderTop />
      {windowWidth <= 768 ? (
        <>
          <s.HeaderImageContainer>
            <s.HeaderImage
              src="https://i.ibb.co/zsD3ncq/Purple-and-Blue-App-Update-Reminder-with-3-D-Mockup-of-Hand-Holding-Smartphone-in-Instagram-Post-Sto.png"
              alt="Seu navegador não suportar esta imagem"
            />
          </s.HeaderImageContainer>
        </>
      ) : (
        <>
          <s.HeaderVideoContainer>
            <s.HeaderVideo autoPlay muted loop>
              <source src="/videos/header.mp4" type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </s.HeaderVideo>
          </s.HeaderVideoContainer>
        </>
      )}
    </>
  );
};
