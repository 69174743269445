export const dataJob = [
  {
    title: "Tráfego Pago",
    img: "./images/trafegopago.png",
    description: (
      <div>
        O tráfego pago é a chave para atingir audiências específicas de maneira
        eficiente e direcionada. Com nossa expertise, cuidadosamente
        desenvolvemos campanhas de anúncios que não apenas aumentam a
        visibilidade da sua marca, mas também geram engajamento e conversões
        reais. Nossa abordagem se baseia em análises detalhadas e otimizações
        contínuas, garantindo que cada centavo do seu investimento seja
        maximizado.
        <br />
        <br />
        Trabalhamos com Google Ads, Meta Ads, TikTok Ads, entre outras dezenas
        de divulgações. Com especialistas capacitados e experientes para trazer
        o sucesso para seu Negócio!
      </div>
    ),
    paste: { name: "paidTraffic", numberFiles: 1 },
  },
  {
    title: "Criação de Website",
    img: "./images/websitecreator.png",
    description: (
      <div>
        Com especialistas de alto nível, seu Website é desenvolvido
        principalmente para gerar leads, conversões e proporcionar uma
        experiência excepcional aos visitantes.
        <br />
        <br />
        Nossa abordagem envolve a criação de um design responsivo, que se adapta
        perfeitamente a dispositivos móveis, tablets e desktops, proporcionando
        uma experiência perfeita em qualquer tela. Além disso, focamos na
        otimização de velocidade, garantindo que seu website carregue
        rapidamente, evitando a perda de visitantes devido a tempos de
        carregamento lentos.
      </div>
    ),
    paste: { name: "webSiteCreator", numberFiles: 3 },
  },
  {
    title: "Criação de aplicativo",
    img: "./images/branding.png",
    description: (
      <div>
        A criação do aplicativo MindPro envolve a construção e gestão da
        identidade digital da marca, incluindo elementos de design,
        funcionalidades, valores e percepções, com o objetivo de proporcionar
        uma experiência única e memorável para os usuários.
        <br />
        <br />
        No MindPro, o desenvolvimento do aplicativo vai além da interface, é uma
        jornada digital que impulsiona a conexão e a interação. Integrando
        tecnologia com estratégia, transformamos sua visão de aplicativo em uma
        experiência autêntica e marcante.
      </div>
    ),
    paste: { name: "branding", numberFiles: 1 },
  },
  {
    title: "Growth Partner",
    img: "./images/design.png",
    description: (
      <div>
        Um Growth Partner é mais do que um fornecedor comum; trata-se de um
        aliado estratégico que colabora ativamente com a empresa cliente para
        impulsionar o crescimento e alcançar o sucesso sustentável. Essa
        parceria envolve compartilhar visão e objetivos, além de trazer
        expertise e recursos adicionais para áreas como marketing digital,
        desenvolvimento de produtos e expansão de mercado.
        <br />
        <br />
        Mais do que simples resultados tangíveis, a relação é construída com
        base em confiança, transparência e compromisso mútuo, destacando-se como
        uma vantagem competitiva sustentável no cenário empresarial atual.
      </div>
    ),
    paste: { name: "design", numberFiles: 1 },
  },
  // {
  //   title: "SEO",
  //   img: "./images/seo.png",
  //   description: (
  //     <div>
  //       SEO (Search Engine Optimization) é um conjunto de práticas e estratégias
  //       voltadas para otimizar a visibilidade e a classificação de um site nos
  //       resultados dos mecanismos de busca, como o Google. O objetivo do SEO é
  //       aumentar a quantidade e a qualidade do tráfego orgânico (não pago) para
  //       um site, melhorando sua relevância e autoridade aos olhos dos mecanismos
  //       de busca.
  //       <br />
  //       <br />
  //       Com um conjunto estratégico de técnicas, nós otimizamos seu site para
  //       garantir que ele alcance as melhores posições nos resultados dos
  //       mecanismos de busca.
  //       <br />
  //       <br />
  //       Nossa equipe de especialistas em SEO trabalha incansavelmente para
  //       aumentar a visibilidade da sua marca, garantindo que ela seja encontrada
  //       por aqueles que realmente importam.
  //     </div>
  //   ),
  //   paste: { name: "seo", numberFiles: 1 },
  // },
  // {
  //   title: "Vídeos Profissionais",
  //   img: "./images/videoprofissionais.png",
  //   description: (
  //     <div>
  //       Os vídeos profissionais não são apenas uma tendência, mas uma
  //       necessidade no cenário de negócios de hoje. Combinando criatividade
  //       visual, narrativa envolvente e impacto emocional, os vídeos têm a
  //       capacidade única de cativar e conectar seu público-alvo de maneira
  //       significativa.
  //       <br />
  //       <br />
  //       Nossa equipe de profissionais experientes em produção de vídeos está
  //       pronta para transformar suas ideias em narrativas visuais envolventes.
  //       Desde vídeos promocionais e explicativos até depoimentos de clientes e
  //       cobertura de eventos, estamos comprometidos em fornecer uma abordagem
  //       criativa e estratégica que ajude sua empresa a se destacar. Eleve sua
  //       comunicação empresarial com vídeos profissionais de qualidade.
  //     </div>
  //   ),
  //   paste: { name: "professionalVideos", numberFiles: 1 },
  // },
  // {
  //   title: "Gestão de redes",
  //   img: "./images/gestaoderedes.png",
  //   description: (
  //     <div>
  //       Com toda certeza as Redes Sociais é uma das melhores formas de captar
  //       leads, gerar conversões e conexões, porém você está utilizando e
  //       usufruindo o melhor das suas redes sociais?
  //       <br />
  //       <br />
  //       Nossos especialistas em redes sociais estão prontos para posicionar sua
  //       marca de maneira estratégica e cativante. Desde o planejamento até a
  //       execução e análise contínua, estamos comprometidos em criar uma presença
  //       online que se destaque. Deixe-nos cuidar da sua gestão de redes sociais
  //       para que você possa focar no crescimento do seu negócio.
  //     </div>
  //   ),
  //   paste: { name: "networkManagement", numberFiles: 1 },
  // },
  // {
  //   title: "Identidade visual",
  //   img: "./images/identidadepessoal.png",
  //   description: (
  //     <div>
  //       A identidade visual é a essência visual da sua marca, representando sua
  //       personalidade, valores e missão. Na Genext, não apenas criamos designs
  //       atraentes, mas também construímos uma identidade que ressoa com seu
  //       público. Do logotipo às cores, tipografia e elementos visuais,
  //       desenvolvemos uma linguagem visual coesa que cria reconhecimento
  //       instantâneo e fortalece a conexão emocional com sua audiência.
  //       <br />
  //       <br />
  //       Nossa abordagem vai além do estético, focando em transmitir a essência
  //       única da sua marca. Cada elemento é pensado estrategicamente,
  //       alinhando-se à sua visão e objetivos. Através de uma colaboração
  //       estreita, garantimos que sua identidade visual não seja apenas uma
  //       imagem, mas uma narrativa visual que se destaca em um mundo cheio de
  //       informações visuais.
  //     </div>
  //   ),
  //   paste: { name: "visual", numberFiles: 1 },
  // },
];
