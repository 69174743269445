import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  text-align: center;
`;

export const H1 = styled.h1`
  color: #141e2b;
  font-family: "Work Sans", Sans-serif;
  font-size: 64px;
  font-weight: 600;
  padding: 15px;
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: -10px;
`;

export const Line = styled.div`
  width: 200px;
  border-bottom: 3px solid #486791;
`;

export const ContainerSobreNosPai = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  font-family: "Sora sans", Sans-serif;
  color: #141e2b;
  text-align: left;
  margin-bottom: 20px;
`;

export const TitleSobreNos = styled.div`
  width: 100%;
  height: auto;
`;

export const SobreNos = styled.div`
  font-family: "DM sans", Sans-serif;
  font-size: 19px;
  text-align: left;
  max-width: 550px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;

  @media only screen and (max-width: 480px) {
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 100%;
  }

  @media only screen and (min-width: 1201px) {
    width: 100%;
  }
`;

export const ContainerSobreNosFilho = styled.div`
  @media only screen and (max-width: 480px) {
    display: ruby-text;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    display: ruby-text;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ImgOndeEstamos = styled.div`
  width: 100%;
  max-width: 550px;
  height: auto;
  margin-right: 20px;
  position: relative;
  overflow-y: hidden;
`;

export const Img = styled.img`
  width: 100%;
  max-width: 550px;
  height: auto;
`;

export const ContainerPulseDots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
`;

export const Pulse = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #220ff0;
  position: absolute;

  animation: ${pulseAnimation} 2s infinite ease;

  top: 60%;
  left: 35%;
`;

export const Dot = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #220ff0;
  position: absolute;

  top: 60%;
  left: 35%;
`;

export const PulseMiami = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #220ff0;
  position: absolute;

  animation: ${pulseAnimation} 2s infinite ease;

  top: 47%;
  left: 25%;
`;

export const DotMiami = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #220ff0;
  position: absolute;

  top: 47%;
  left: 25%;
`;

export const PulseCuracao = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #220ff0;
  position: absolute;

  animation: ${pulseAnimation} 2s infinite ease;

  top: 52%;
  left: 28%;
`;

export const DotCuracao = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #220ff0;
  position: absolute;

  top: 52%;
  left: 28%;
`;
