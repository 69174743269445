import * as s from "./companyNumbersStyle.jsx";
import React, { useEffect, useState, useRef } from "react";
import CountUp from "react-countup";

export const CompanyNumbers = () => {
  const [state, setState] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [size, setSize] = useState(69);
  const [sizep, setSizep] = useState(19);

  const scrollDisparoRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (scrollDisparoRef.current) {
        const { top } = scrollDisparoRef.current.getBoundingClientRect();

        const currentScrollPos = window.scrollY;

        setPrevScrollPos(currentScrollPos);

        if (top < window.innerHeight) {
          setState(true);
          if (prevScrollPos > currentScrollPos) {
            setSize(size - 0.2);
            setSizep(sizep - 0.2);
          } else {
            setSize(size + 0.2);
            setSizep(sizep + 0.2);
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [size, prevScrollPos, sizep]);

  return (
    <>
      <s.Container ref={scrollDisparoRef}>
        <s.Content>
          <div>
            <s.NumberAndDescription>
              <s.Number $size={size}>
                {state ? <CountUp end={532} /> : "532"}
              </s.Number>
              <s.NumberDescription $sizep={sizep}>
                Clientes Satisfeitos
              </s.NumberDescription>
            </s.NumberAndDescription>
            <s.NumberAndDescription>
              <s.Number>
                <s.Number $size={size}>
                  {state ? <CountUp end={168} /> : "168"}
                </s.Number>
              </s.Number>
              <s.NumberDescription $sizep={sizep}>
                Contas Gerenciadas
              </s.NumberDescription>
            </s.NumberAndDescription>
          </div>

          <div>
            <s.NumberAndDescription>
              <s.Number>
                <s.Img src="https://i.ibb.co/19BXCWn/1-FUNDO-TRANSPARENTE.png" />
              </s.Number>
            </s.NumberAndDescription>
            <s.NumberAndDescription4>
              <s.Number>
                <s.Number $size={size}>
                  {state ? <CountUp end={62} /> : "62"}
                </s.Number>
              </s.Number>
              <s.NumberDescription $sizep={sizep}>
                Prêmios Marketing
              </s.NumberDescription>
            </s.NumberAndDescription4>
          </div>
          <div>
            <s.NumberAndDescription>
              <s.Number>
                <s.Number $size={size}>
                  {state ? <CountUp end={2} /> : "2"}
                </s.Number>
              </s.Number>
              <s.NumberDescription $sizep={sizep}>
                Projetos Internacionais
              </s.NumberDescription>
            </s.NumberAndDescription>
            <s.NumberAndDescription>
              <s.Number>
                <s.Number $size={size}>
                  {state ? <CountUp end={234} /> : "234"}
                </s.Number>
              </s.Number>
              <s.NumberDescription $sizep={sizep}>
                Sites Desenvolvidos
              </s.NumberDescription>
            </s.NumberAndDescription>
          </div>
        </s.Content>
      </s.Container>
    </>
  );
};
