import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 25px 25px 25px;
`;

export const Content = styled.div`
  @media only screen and (max-width: 480px) {
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: flex;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: flex;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
  }
`;

export const BoxContent = styled.div`
  width: 550px;
`;

export const BoxCarousel = styled.div`
  width: 100%;
  height: auto;
  max-width: 550px;
`;

export const BoxTitle = styled.div`
  background-color: #220ff0;
  width: calc(100% - 40px);
  height: auto;
  padding: 10px;
  margin: 0 100px 15px 0px;
  box-shadow: 5px 7px 10px 0px rgba(0, 0, 0, 0.5);
`;

export const Title = styled.p`
  font-family: "Sora sans", Sans-serif;
  font-size: 30px;
  color: #ffffff;
  font-weight: 600;
`;

export const BoxDescription = styled.div`
  overflow-y: hidden;

  @media only screen and (max-width: 480px) {
    margin: 0px 100px 120px 0px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 0px 100px 120px 0px;
    width: auto;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 100px 120px 0px;
    width: auto;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    width: 380px;
  }

  @media only screen and (min-width: 1201px) {
    width: 400px;
  }
`;

export const Button = styled.button`
  background-color: #220ff0;
  border: none;
  width: 220px;
  height: calc(auto + 20px);
  padding: 10px;
  font-family: "DM sans", Sans-serif;
  margin-top: 30px;
  color: #ffffff;
  font-size: 18px;
  border-radius: 5px;
  transition: all 300ms ease;
  position: absolute;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const Description = styled.div`
  font-family: "DM sans", Sans-serif;
  font-size: 19px;
  text-align: left;
  overflow-y: hidden;
`;
