import * as s from "./aboutStyle.jsx";

export const About = () => {
  return (
    <>
      <s.Container>
        <s.H1>Sobre nós</s.H1>
        <s.LineContainer>
          <s.Line></s.Line>
        </s.LineContainer>
        <s.ContainerSobreNosPai>
          <s.ContainerSobreNosFilho>
            <s.SobreNos>
              <s.Title>MIND</s.Title>
              <s.TitleSobreNos>
                A <strong>MIND PRO</strong> é uma agência especializada em
                oferecer soluções de Marketing integradas e eficazes, projetadas
                para atender às necessidades únicas de cada cliente. Temos
                projetos em São Paulo, Brasil e em Miami, Estados Unidos e
                Curaçao.
                <br />
                <br />
                <br />
                Nós combinamos estratégias inovadoras, criatividade excepcional
                e expertise técnica para criar campanhas de marketing
                personalizadas e convincentes, que geram resultados reais.
                <br />
                <br />
                <br />
                Nossa equipe talentosa é composta por profissionais experientes
                em diversas áreas do marketing focados principalmente na
                qualidade de seus serviços.
              </s.TitleSobreNos>
            </s.SobreNos>
            <s.ImgOndeEstamos>
              <s.Img src="/images/paises.png" />
              <s.ContainerPulseDots>
                <s.Pulse />
                <s.Dot />
              </s.ContainerPulseDots>
              {/*  */}
              <s.ContainerPulseDots>
                <s.PulseMiami />
                <s.DotMiami />
              </s.ContainerPulseDots>
              {/*  */}
              <s.ContainerPulseDots>
                <s.PulseCuracao />
                <s.DotCuracao />
              </s.ContainerPulseDots>
              {/*  */}
            </s.ImgOndeEstamos>
          </s.ContainerSobreNosFilho>
        </s.ContainerSobreNosPai>
      </s.Container>
    </>
  );
};
