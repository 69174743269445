import styled from "styled-components";

export const Container = styled.div`
  background-color: #e9eae6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 100px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 920px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 480px) {
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: flex;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: flex;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
  }
`;

export const Img = styled.img`
  height: auto;
  max-width: 100%;
  width: 192px;
  border: none;
  border-radius: 0;
  box-shadow: none;
`;

export const NumberAndDescription = styled.div`
  text-align: center;
  margin: 30px;
`;

export const NumberAndDescription4 = styled.div`
  text-align: center;
  margin: 20px;
`;

export const Number = styled.div`
  font-family: "Sora", Sans-serif;
  font-size: 69px;
  font-size: ${({ $size }) =>
    $size <= 69 ? "69px" : $size >= 78 ? "78px" : `${$size}px`};
  font-weight: 600;
  color: #220ff0;
  text-align: center;
  transition: 1200ms ease all;
`;

export const NumberDescription = styled.p`
  color: #84a8f3;
  font-family: "Sora", Sans-serif;
  font-weight: 800;
  font-size: 19px;
  font-size: ${({ $sizep }) =>
    $sizep <= 19 ? "19px" : $sizep >= 22 ? "22px" : `${$sizep}px`};
  transition: 1200ms ease all;
`;
