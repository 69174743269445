import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
  @media only screen and (max-width: 480px) {
    margin-top: 65px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-top: 65px;
  }
`;

export const ContentLine = styled.div`
  @media only screen and (max-width: 480px) {
    margin: 0 20px 0 40px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin: 0 25px 0 50px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 25px 0 50px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    margin: 0 25px 0 50px;
  }

  @media only screen and (min-width: 1201px) {
    margin: 0 25px 0 50px;
  }
`;

export const MySlider = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  @media only screen and (max-width: 480px) {
    /* width: 400px; */
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    display: flex;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    display: flex;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    display: flex;
  }

  @media only screen and (min-width: 1201px) {
    display: flex;
  }
`;

export const SliderGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100px;
  height: 600px;
  overflow: hidden;
  position: relative;
  z-index: 1;
`;

export const Line = styled.span`
  height: 580px;
  position: absolute;
  width: 2px;
  z-index: -1;
  background-color: #b0b7d04d;
  left: 10px;
  top: 5px;

  /* @media only screen and (max-width: 480px) {
    top: 50px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    top: 50px;
  } */
`;

export const Dots = styled.span`
  background-color: #b0b7d0;
  border-radius: 100%;
  position: relative;
  width: 23px;
  height: 23px;
  color: ${({ $index, $active }) =>
    $index === $active ? "#ffffff" : "transparent"};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "DM sans", Sans-serif;
  font-size: 16px;
  transition: 400ms cubic-bezier(0.32, 0, 0.67, 0) all;
  transform: ${({ $index, $active }) =>
    $index === $active ? "scale(1)" : "scale(0.5)"};

  &:hover {
    cursor: pointer;
  }
`;

export const ContentDescription = styled.div`
  overflow: hidden;
  scroll-behavior: smooth;
  @media only screen and (max-width: 480px) {
    height: 335px;
    margin-right: 50px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    height: 310px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    height: 240px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    height: 240px;
  }

  @media only screen and (min-width: 1201px) {
    height: 240px;
  }
`;

export const Place = styled.h1`
  font-family: "DM sans", Sans-serif;
  font-size: 50px;
  margin-bottom: 20px;
  color: #ffffff;
`;

export const Description = styled.h3`
  font-family: "DM sans", Sans-serif;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  font-size: 16px;
  text-align: justify;
  color: #ffffff;
  font-weight: 400;
`;

export const Button = styled.button`
  background-color: #1d5595;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  padding: 15px 30px;
  font-family: "DM Sans", Sans-serif;
  color: #ffffff;
  @media only screen and (max-width: 480px) {
    margin-bottom: 60px;
  }

  @media only screen and (min-width: 481px) and (max-width: 768px) {
    margin-bottom: 60px;
  }

  @media only screen and (min-width: 769px) and (max-width: 1024px) {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1025px) and (max-width: 1200px) {
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 1201px) {
    margin-bottom: 30px;
  }

  &:hover {
    cursor: pointer;
  }
`;
